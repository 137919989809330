import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";
export default class NewsList {
  constructor() {
    this.grid           = $(".news--grid--list");
    this.gridList       = $(".data--bind");
    this.mainLoader     = this.grid.find(".main--loader");
    this.searchErrorBlock = this.grid.find(".results--message--none");
    this.loadMoreBtn    = this.grid.find(".load-more-block");

    this.media       = this.grid.find(".media--drop");
    this.keyword       = this.grid.find(".keyword");
    this.sortBy         = this.grid.find(".sort-by");

    this.tabFilters     = $(".tab-filters");
    this.showResult     = $(".show-result");
    this.clearResult    = $(".clear-result");

    this.bindEvents();
  }

  bindEvents = () => {
    this.page = 1;
    this.media.on("click", this.mediaChange);
    this.sortBy.on("click", this.sortChange);

    this.keyword.keyup(this.delayLoad);
    this.loadMoreBtn.on("click", this.nextPage);
    this.delayLoad();
    gsap.registerPlugin(ScrollTrigger);
    this.appendImgs()
    // window.onload = this.lazyPage();
  };

  mediaChange = (e) => {
    const target = $(e.currentTarget);
    this.media.removeClass('active');
    target.addClass('active');
    this.delayLoad();
  };

  sortChange = (e) => {
    const target = $(e.currentTarget);
    this.sortBy.removeClass('active');
    target.addClass('active');
    this.delayLoad();
  };

  delayLoad = () => {
    this.resetPage();
    this.showLoader();
    this.clearContainer();
    this.filterSearch();
  };

  clearResults = () => {
    this.resetPage();
    this.clearFilters();
    this.showLoader();
    this.clearContainer();
    this.filterSearch();
  };

  clearFilters = () => {
    this.interest.val('').trigger("change");
    this.keyword.val('');
    this.topic.val('').trigger("change");
    this.sortBy.val('asc').trigger("change");
    this.grid.find('filters--popup').removeClass('active');
  };

  filterSearch = () => {
    $.ajax({
      dataType: "json",
      type: "GET",
      data: this.formParams(),
      url: this.getCurrentPagePath(),
      success: this.applyFilters,
    });
  };
  
  applyFilters = (response) => {
    let data = "";
    this.hideLoader();
    if (this.grid.length) {
      if (response.success) {
        data = response.pages;
        ScrollTrigger.refresh();
      }
      if (data) {
        this.gridList.html($(data));
        this.hideErrorBlock();
      } else {
        this.clearContainer();
        this.showErrorBlock();
      }
      this.appendImgs()
      ScrollTrigger.refresh();
    }

    //new Animations();
    this.checkLoadMore(response.loadMore);
  };

  nextPage = (e) => {
    e.preventDefault();
    this.addPage();
    this.loadMorePosts();
  };
  loadMorePosts = () => {
    $.ajax({
      dataType: "json",
      type: "GET",
      data: this.formParams(),
      url: this.getCurrentPagePath(),
      success: this.appendItems,
    });
  };
  appendImgs = () => {
   // alert(1);
    let appendBGs = document.querySelectorAll(".load_bg")
    //console.log(appendBGs);
    appendBGs.forEach(function (el) {
      //console.log("this is it: " + el);
      let s = el.dataset.src;
      
      el.style.backgroundImage = "url(" + s + ")";
      // el.removeAttribute('data-src')
      el.classList.remove("load_bg");
    });

   // this.lazybuild();
  };

  // lazybuild = () => {
  //   let lazy = document.querySelectorAll(".lazy");

  //   if (lazy.length != 0) {
  //     lazy.forEach(function (e) {
  //       let $this = e,
  //         tmp = document.createElement("div"),
  //         spinner = document.createElement("div");

  //       tmp.className = "_temp full_bg";
  //       spinner.className = "spinner";

  //       tmp.append(spinner);
  //       $this.append(tmp);
  //     });
  //   }
  // };
  appendItems = (response) => {
    if (response.success) {
      this.gridList.append(response.pages);
      ScrollTrigger.refresh();
      this.appendImgs();
    }
    this.checkLoadMore(response.loadMore);
  };

  checkLoadMore = (value) => {
    value ? this.showButton() : this.hideButton();
  };

  clearContainer = () => {
    this.gridList.html("");
  };

  hideLoader = () => {
    this.mainLoader.css("display", "none");
  };

  showLoader = () => {
    this.mainLoader.css("display", "block");
  };

  hideErrorBlock = () => {
    this.searchErrorBlock.addClass("hidden");
  };

  showErrorBlock = () => {
    this.searchErrorBlock.removeClass("hidden");
  };

  hideButton = () => {
    this.loadMoreBtn.attr("disabled", true).addClass("hidden");
  };

  showButton = () => {
    this.loadMoreBtn.attr("disabled", false).removeClass("hidden");
  };

  addPage = () => {
    this.page += 1;
  };

  resetPage = () => {
    this.page = 1;
  };

  getCurrentPagePath = () => {
    return location.href;
  };

  getMedia = () => {
    return this.grid.find(".media--drop.active").data('id') ? 
      encodeURI(this.grid.find(".media--drop.active").data('id')) : 
      "";
  };

  getKeyword = () => {
    return this.keyword.val() ? encodeURI(this.keyword.val()) : "";
  };

  getSortBy = () => {
    return this.grid.find(".sort-by.active").data('id') ? 
      encodeURI(this.grid.find(".sort-by.active").data('id')) : 
      "";
  };

  formParams = () => {
    return {
      page:     this.page,
      keyword:  this.getKeyword(),
      media:    this.getMedia(),
      sort:     this.getSortBy(),
    }
  };
}
