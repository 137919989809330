import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";

gsap.registerPlugin(ScrollTrigger);

export default class Products {
  constructor() {
    this.initialize();
    this.bindEvents();
  }

  initialize = function () {
    this.$window = $(window);
    this.$document = $(document);
    this.bindList = $(".products--page");
    this.footer = $("footer");
    this.check = true;
  };

  bindEvents = function () {
    this.page = 1;
    this.autoLoad = true;
    this.$window.on("scroll", this.onScroll);
    window.addEventListener("scroll", this.pageScroll);
  };

  onScroll = () => {
    let scrollTop = this.$window.scrollTop();
    if (this.autoLoad && this.isAtBottom(scrollTop) && this.check) {
      this.nextPage();
    }
  };

  nextPage = () => {
    if (this.check) {
      this.check = false;
      this.timer = setTimeout(this.loadMore, 250);
    }
  };
  loadMore = () => {
    this.addPage();
    $.ajax({
      dataType: "html",
      type: "GET",
      data: {
        page: this.page,
      },
      url: this.getCurrentPagePath(),
      success: this.appendItems,
    });
  };
  appendItems = (response) => {
    response = JSON.parse(response);

    if (response.success) {
      this.bindList.append(response.data);
      this.appendImgs();
    } else {
      this.autoLoad = false;
    }

    this.check = true;
  };

  addPage = () => {
    this.page += 1;
  };

  resetPage = () => {
    this.page = 1;
  };

  getCurrentPagePath = () => {
    return location.origin + location.pathname;
  };

  isAtBottom = (scrollTop) => {
    return scrollTop + this.$window.height() > this.$document.height() - 2000;
  };

  appendImgs = () => {
    let appendBGs = document.querySelectorAll(".load_bg"),
      iMGs = document.querySelectorAll(".load_img"),
      staticBG = document.querySelectorAll(".static_bg");

    staticBG.forEach(function (el) {
      let img = el.querySelector("i"),
        s = img.dataset.src,
        newImg = new Image();

      img.removeAttribute("data-src");

      img.style.backgroundImage = "url(" + s + ")";

      newImg.onload = function () {
        gsap.to(img, 1, { autoAlpha: 1, ease: "power3.out" });
      };

      newImg.src = s;
    });

    iMGs.forEach(function (el) {
      let s = el.dataset.src;

      el.removeAttribute("data-src");
      el.setAttribute("src", s);
      el.classList.remove("load_img");
      ScrollTrigger.refresh();
    });

    appendBGs.forEach(function (el) {
      let s = el.dataset.src;

      el.style.backgroundImage = "url(" + s + ")";
      // el.removeAttribute('data-src')
      el.classList.remove("load_img");
    });

    this.lazybuild();
  };

  lazybuild = () => {
    let lazy = document.querySelectorAll(".lazy");

    if (lazy.length != 0) {
      lazy.forEach(function (e) {
        let $this = e,
          tmp = document.createElement("div"),
          spinner = document.createElement("div");

        tmp.className = "_temp full_bg";
        spinner.className = "spinner";

        tmp.append(spinner);
        $this.append(tmp);
      });
    }
  };

  pageScroll = (horizontal) => {
    let lazy = document.querySelectorAll(".lazy");
    if (lazy.length != 0) {
      lazy.forEach(function (e) {
        let $this = e,
          getIMG = $this.querySelector("img"),
          src = getIMG.dataset.src;
        if (ScrollTrigger.isInViewport($this, 0, horizontal)) {
          if (!$this.classList.contains("inview")) {
            $this.classList.add("inview");
            console.log("comes here", $this);

            let spinner = $this.querySelector(".spinner");
            let tmp = $this.querySelector("._temp");
            let img = $this.querySelector("img");
            let newImg = new Image();

            img.setAttribute("src", src);

            newImg.onload = function () {
              ScrollTrigger.refresh();

              spinner.classList.add("pause");

              gsap.to(spinner, 0.5, {
                autoAlpha: 0,
                ease: "power3.out",
                onComplete: function () {
                  $this.classList.add("ready");
                  img.classList.add("ready");
                  tmp.remove();
                },
              });

              gsap.to(img, 1, {
                autoAlpha: 1,
                ease: "power3.out",
                delay: 0.5,
                onComplete: function () {
                  ScrollTrigger.refresh();
                },
              });
            };

            newImg.src = src;
          }
        }
      });
    }
  };

  lazyLoad = (element, s) => {};
}
